.plp-actions .plp-actions-button.refinements-toggle-button {
	@include respond(largeUp) {
		height: 78px;
		display: flex;
		align-items: center;

		a {
			line-height: normal;
		}

		.toggle-refine-title.display-desktop-only {
			margin-right: 10px;
		}

		.results-count {
			margin-left: 0;
		}
	}
}

.plp-actions .plp-actions-button .sort-by {
	@include respond(largeUp) {
		padding: 11px 0 11px 20px;
	}
	form {
		@include respond(largeUp) {
			display: flex;
			justify-content: space-between;
			align-items: center;

			label {
				margin-right: 5px;
				margin-bottom: 0;
			}
		}
	}
}
