#wrapper.pt_checkout #main #secondary .checkout-order-totals {
	.japan-tax-vat,
	.japan-tax-number {
		display: none !important;
	}
}


.payment-method[data-method=AMAZON_PAY] {
    min-height: auto !important;
    padding: 20px 32px!important;

    .form-caption {
        margin: 15px 0 0 0 !important;
        padding: 0 !important;
    }

    .payment-method {
        border-bottom: 0 !important;
        padding: 20px 32px 0 32px !important;
    }
}
