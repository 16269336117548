#wrapper.pt_order #main .details-box .order-payment-summary .order-totals-table tr td.japan-tax-vat,
#wrapper.pt_order-confirmation #main .details-box .order-payment-summary .order-totals-table tr td.japan-tax-vat {
	text-align: right;
}


#wrapper.pt_order-confirmation #main .order-confirmation-details.order-conf-page .details-box .flex-details.order-payment-summary {
	padding-bottom: 45px;
	position: relative;
}

#wrapper.pt_order-confirmation #main .order-confirmation-details.order-conf-page .details-box .flex-details.order-payment-summary .japan-tax-number {
	position: absolute;
	left: 25px;
	bottom: 20px;
}
