$hansans: "source-han-sans-cjk-ja", sans-serif;

// footjoy fonts
$titillium: $hansans;
$oswald: $hansans;
$arial: $hansans;
$din-condensed: $hansans;
$din2014narrow: $hansans;
$athelas: $hansans;
$neuzeit: $hansans;
// usage: @include font-regular();
// ex: @include font-regular(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-regular(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-regular($size:"", $breakpoint:"") {
    font-family: $hansans;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-semibold($size:"", $breakpoint:"") {
    font-family: $hansans;
    font-weight: 700;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-bold($size:"", $breakpoint:"") {
    font-family: $hansans;
    font-weight: 700;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-regular-secondary($size:"", $breakpoint:"") {
    font-family: $hansans;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-small($size:"", $breakpoint:"") {
    font-family: $hansans, serif;
    font-weight: 400;
    font-style: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-special($size:"", $breakpoint:"") {
    font-family: $hansans, serif;
    font-weight: 700;
    font-style: normal;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}







