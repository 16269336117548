.pt_product-search-result .product-tile {
	.product-tile-details {
		@include respond(largeUp) {
			min-height: 175px;
		}
	}

	.product-name {
		@include respond(largeUp) {
			width: 100%;
			padding-right: 0;
		}
	}

	.product-pricing {
		@include respond(largeUp) {
			position: static !important;
		}
	}

	.product-swatches ul {
		@include respond(largeUp) {
			margin-top: 0;
		}
	}

	.pt_product-search-result .product-tile .members-only-login {
		@include respond(largeUp) {
			position: static;
		}
	}

	&:hover .pr-clp-raing,
	&:hover .product-swatches,
	&:hover .product-category {
		@include respond(largeUp) {
			position: static;
		}
	}
}
