.pdp-recommendations {
    .product-listing-1x4 {
        .grid-tile {
            .product-tile {
                .product-name {
                    padding-right: rem(105px);
                }
            }
        }
    }
}


.customizable-product-pdp {
    .product-variations ul li#design-your-own-swatch, li[id^="design-your-blank-swatch"] {
        &.new-design .text-own {
            padding: 23px 0;
        }
    }
}