@import "../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/shop_the_look";

.shop-the-look {
    .stl-container {
        .stl-product-details {
            .product-name-price {
                font-size: rem(13px);

                @include respond(largeUp) {
                    font-size: rem(15px);
                }

                .name-link {
                    font-size: rem(13px);
                    max-width: 30%;

                    @include respond(largeUp) {
                        font-size: rem(15px);
                        max-width: 42%;
                    }
                }
            }

            .product-sale-price,
            .product-sales-price {
                width: 70%;

                @include respond(largeUp) {
                    width: 58%;
                }
            }
        }
    }
}