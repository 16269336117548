footer.foot--footjoy {
	#footerSignup {
		form#email-alert-signup {
			button {
				width: 45px;
				height: 45px;
			}
		}
	}
}

.footer__links__wrapper {
    &.jp {
        .footer__links {
            .countries-container > a {
                height: 19px;
                display: block;
                line-height: 17px;
            }
        }
    }
}
