#wrapper.pt_cart #main #primary #cart-items-form .cart-footer .action-carousel .cart-order-totals {
	.japan-tax-vat,
	.japan-tax-number {
		display: none !important;
	}
}

.cart-actions-top {
    .cart-action-checkout {
        width: 100%;
        float: none;
    }

    .cart-actions-top-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        @media (max-width: 879px) {
            display: flex;
            flex-direction: column-reverse;
        }

        button.go-checkout {
            float: none !important;
            margin: 0;
            @media (min-width: 878px) {
                margin: 0 0 0 15px;
            }
            @media only screen and (min-width: 880px) and (max-width: 1199px) {
                width: 230px !important;
            }
        }

        .amazon-pay-button-component {
            width: 250px !important;
            margin-top: 2px;
            @media only screen and (min-width: 880px) and (max-width: 1199px) {
                width: 230px !important;
            }
            @media (max-width: 879px) {
                width: 100% !important;
                margin-top: 10px;
            }
        }

        .cart-action-paypal {
            @media (min-width: 878px) {
                margin: 0 0 0 15px !important;
            }
            @media (max-width: 879px) {
                margin: 10px 0 0 0 !important;
            }

            @media only screen and (min-width: 880px) and (max-width: 1199px) {
                width: 230px !important;
            }
        }

        .amazon-pay-button-component {
            @media (max-width: 878px) {
                margin-top: 10px !important;
            }
        }
    }
}

.cart-actions {
    .amazon-pay-button-component {
        margin-top: 0 !important;
    }
}

.cart-actions-another {
    margin-top: 0 !important;
    float: none !important;
    text-align: right;
    clear: both;

    .cart-payment-bottom {
        float: none;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .cart-action-paypal {
        margin: 0 0 10px 0 !important;
    }

    .amazon-pay-button-component {
        width: 100% !important;
        @media (min-width: 879px)  {
            margin: 0;
        }

        #amazon-pay-cart-one-time-button-another {
            @media (min-width: 879px) {
                width: 100% !important;
            }
        }
    }
}

.amazon-pay-mini-cart-button-component {
    width: 282px !important;
}

.footer-bottom {
    .banner-security {
        justify-content: center !important;

        .secure {
            float: none !important;
            @include respond(largeUp) {
                min-height: 30px;
                line-height: 30px !important;
            }
        }
    }
}
