.menu-utility-user {
    li .account-toggle-content > div.content-asset {
        max-height: none;
        margin-top: -30px;

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
