#wrapper.pt_account #primary .account-toggle-content .content-asset p {
	padding-bottom: 0;
	width: 100%;
	margin: 1em 0;
}

#wrapper.pt_account {
	#primary {
		#RegistrationForm.registered-form {
			.form-row {
				label[for='dwfrm_profile_customer_furiganaName'],
				label[for='dwfrm_profile_customer_furiganaSurname'],
                label[for='dwfrm_profile_customer_firstname'] {
					display: none;
				}
                label[for='dwfrm_profile_customer_lastname'] {
                    display: block;
                }
			}
			.form-row.form-row--dwfrm_profile_customer_email,
			div[class*='form-row--dwfrm_profile_login_password'] {
				.form-caption {
					display: block;
					margin-left: 0;
				}
			}
		}
	}
}



.fj_account #wrapper.pt_account #main {
    .shoe-finder {
        display: none;
    }
}

#wrapper.pt_customer-service #primary h1 {
	@include respond(small) {
		font-size: 16px;
	}
}
