#secondary {
	.refinement.size a {
		@include font-size(9px);
	}
}

.members-only-modal  {
    .password-link.account-link {
        span {
            display: none;
        }
    }
}
